#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0rem;
}

.contactPageTitle {
    font-weight: 500;
    font-size: 3rem;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
}

.contactDescription {
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
    text-align: center;
}

.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .message {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: #041f38;
}

.captcha {
    margin: 0.5rem;
}

.submitButton {
    background: white;
    border: none;
    padding: 0.75rem 3.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    cursor: pointer;
}