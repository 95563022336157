#resume {
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 90%;
    padding: 3rem 1rem;
}

@media screen and (max-width: 1000px) {
    #resume {
        max-width: 95%;
    }

    .mobileHidden {
        display: none;
    }

    .resumeTitle {
        font-size: 2rem;
    }
}

.timelineTitle {
    padding-top: 1.25rem;
}

.timelineOrg {
    font-weight: 600;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
}

.timelineDot {
    height: 2.25rem;
    width: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white !important;
}

.timelineDotImage {
    height: inherit;
    width: inherit;
}

.timelineHighlight {
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.timelineYear {
    margin-top: 0.5rem;
}

.resumeTitle {
    font-weight: 500;
    font-size: 3rem;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
}

/* #0a9490 */

.resumePoint {
    font-size: 1rem;
    list-style: none;
    margin-top: 0.5rem;
}

.resumeDescription {
    
}