.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(11, 93, 166, 0.65);
    border-radius: 0.5rem;
    color: white;
    text-align: left;
    width: 75%;
    margin-bottom: 1rem;
}

.slide {
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px white;
    width: 100%;
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2rem;
}

.slide-hidden {
    display: none;
}

.arrow {
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: white;
    filter: drop-shadow(0px 0px 5px #555)
}

.arrow:hover {
    cursor: pointer;
}

.arrow-left {
    left: 1rem;
}

.arrow-right {
    right: 1rem;
}

.indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
}

.indicator {
    background-color: white;
    height: 0.2rem;
    width: 1rem;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0rem 0.4rem;
    cursor: pointer;
    border-radius: 1rem;
}

.indicator-inactive {
    background-color: #041f38;
}

.author {
    color: #e5e4e2;
    padding: 2rem 0rem 0rem 2rem;
    margin-bottom: 5%;
}

.author p {
    margin: 0px;
}

.author-name {
    font-weight: 500;
    font-size: medium;
}

.author-role {
    font-style: italic;
    font-size: small;
}

.quotation {
    color: white;
    size: auto;
    min-width: 10px;
}

@media only screen and (max-width: 1000px) {
    .carousel {
        width: 80%;
    }

}

@media only screen and (max-width: 850px) {
    .arrow {
        display: none;
    }
}

@media only screen and (max-width: 700px) {
    .author-role {
        padding-bottom: 1rem;
    }
}