#home {
    height: 100%;
    width: 100vw;
    margin: 0 auto;
    overflow: hidden;
    background-image: url("../images/header.jpg");
    background-position: 75% 25%;
    background-repeat: no-repeat;
    background-size: cover;
}

.introContent {
    height: 100vh;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #0b5da6;
    margin-left: 2rem;
}

.smallHeader {
    font-size: 1.75rem;
    font-weight: 200;
}

.emphasizedHeader {
    color: #0a9490;
}

.introParagraph {
    font-size: large;
    font-weight: 200;
}

.homeButton {
    background: white;
    margin: 1rem 0;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    font-size: medium;
    width: fit-content;
    padding-right: 2.5rem;
    text-decoration: none;
    color: black;
    opacity: 80%;
}

.homeButton:hover {
    opacity: 100%;
    transition: opacity 0.5s;
}

.homeButtonImage {
    object-fit: cover;
    margin-right: 0.5rem;
    height: 1.5rem;
    width: auto;
}

.additionalContent {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 850px) {
    .mobileCenter {
        text-align: center;
    }

    .introContent {
        margin-left: 0rem;
        margin-top: 15vh;
        align-items: center;
    }

    .smallHeader {
        font-size: 1rem;
        background: white;
        border-radius:0.25rem;
    }

    .largeHeader  {
        font-size: 2rem;
        text-align: center;
        background: white;
        border-radius:0.25rem;
    }

    .introParagraph {
        text-align: center;
        background: white;
        border-radius:0.25rem;
    }

    .additionalContent {
        justify-content: center;
        align-items: center;
    }

    .homeButton {
        padding: 0.5rem 1.5rem;
        opacity: 100%;
    }

    .homeButtonImage {
        height: 1rem;
    }
}