#about {
    overflow: hidden;
    width: 100vw;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.aboutTitle {
    font-size: 3rem;
    font-weight: 500;
    color: white;
    padding-top: 1rem;
}

.aboutSubTitle {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    color: #0a9490;
}

.aboutContent {
    font-weight: 200;
    font-size: 1rem;
    max-width: 50rem;
}

.aboutBars {
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.aboutBar {
    display: flex;
    align-items: center;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background: #041f38;
}

.aboutImage {
    object-fit: cover;
    width: 10rem;
    height: auto;
    margin-right: 2rem;
    padding: 0.5rem;
}

.aboutBarText>p {
    font-size: 1rem;
    font-weight: 200;
}

.skillsTitle {
    padding-top: 1rem;
    font-size: 1.75rem;
    font-weight: 350;
    color: #0a9490;
}

.skillsLogos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.skillsLogoImg {
    height: 2.5rem;
    width: auto;
    margin: 0.5rem;
}

.skillsSpread {
    display: inline-block;
    width: 100%;
}

.skillsText {
    text-align: justify;
    font-weight: 250;
    font-size: 1.25rem;
    word-spacing: 2.5rem;
}

@media screen and (max-width: 1250px) {
    #about {
        max-width: 90%;
    }

    .aboutBars {
        max-width: 85%;
    }
    .aboutBar {
        padding: 1.25rem 1.75rem;
        margin: 0.5rem;
    }
    .aboutImage {
        width: 7.5rem;
        height: auto;
    }
    .aboutSubTitle {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
    }
    .skillsTitle {
        font-size: 1.5rem;
    }
    .skillsText {
        font-size: 1.25rem;
    }

    .skillsLogoImg {
        height: 2.25rem;
    }
}

@media screen and (max-width: 1000px) {
    #about {
        margin: 0;
        max-width: 100%;
    }
    .aboutBars {
        max-width: 90%;
    }
    .aboutBar {
        flex-direction: column;
        padding: 1rem 1.5rem;
        margin: 1.5rem 0.5rem;
    }
    .aboutImage {
        margin-right: 0rem;
    }
    .aboutTitle {
        margin: 0;
    }
    .aboutSubTitle {
        font-size: 1.5rem;
        font-weight: 500;
        padding-bottom: 0.5rem;
    }
    .aboutContent {
        font-size: 2rem;
    }
    .skillsTitle {
        font-size: 1.25rem;
    }
    .skillsText {
        font-size: 1rem;
    }
    .skillsLogoImg {
        height: 2rem;
    }
}